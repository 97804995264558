import config from "../config";

import {getLoggedinUser} from "./apiCore"
import { getChatUserConversations } from "../redux/chats/actions";


let uid = 0
const connectSocket = (user : any) => {
  console.log("<< user >>: ", user)
  if(!user)
    return;
  let uid = 1
  if (user) {
    uid = user.user_id
  }
  const s = new WebSocket(config.WS_API_URL + uid + `/chat/`);
  console.log("Connected");
  // s.addEventListener('message', (event) => {
  //   // ToDo. The constructor return data
  //   // {action: "onlineUser", userList: [1]}
  //   // This info is relevant to set the status Active of the different CONTACTS
  //   const data = JSON.parse(event.data);
  //   console.log(data)
  // });
  return s
};



interface MessageData {[key: string]: any;}

class APIWSClient {
  socket : any = null;
  private static instance: APIWSClient
  private isLogin: boolean = false
  constructor() {
    console.log("In here connecting")
    if(!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      console.log("In here connecting222")
      const user = getLoggedinUser()
      this.socket = connectSocket(user)

    }
  }

  public static getInstanceObject(): APIWSClient {
    return APIWSClient.instance;
  }
  public static getInstance(): APIWSClient {
  if (!APIWSClient.instance) {
    console.log("Here in instance")
    APIWSClient.instance = new APIWSClient()
  }
  console.log("Already Here in instance")
  return APIWSClient.instance
}
public setLogin(login: boolean) {
    this.isLogin = login;
}

  onDisconnect = () => {
        if(this.socket && this.isLogin)
        {
            this.socket.onclose(()=>{
              console.log("On close")
              const user = getLoggedinUser()
              if(user)
                this.socket = connectSocket(user)
            })

        }
  }
  on = (event: string, callback: (data: MessageData) => void) => {

    if (event === 'message') {
      this.socket.onmessage = (event : any) => {
        const data = JSON.parse(event.data);
        console.log('receiving information ')
        console.log('event is ', data)
        callback(data);
      };
    }
  };

  emit = (event: string, data?: MessageData): void => {
    if (event === 'message') {
      this.socket.send(JSON.stringify(data));
    }
  };
  disconnect = ()=>{
    if(this.socket) this.socket.close()
    this.socket = null
  }
  off = (event: string): void => {
    if (event === 'message') {
      this.socket.onmessage = null;
    }
  };
}

export { APIWSClient };
