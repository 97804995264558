import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import PhoneInput from "react-phone-number-input";

interface DataTypes {
  phone: string | null;
  name: string | null;
  message: string | null;
}
interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: any) => void;
}
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
}: InviteContactModalProps) => {
  /*
  data input handeling
  */
  const [data, setData] = useState<DataTypes>({
    phone: null,
    name: null,
    message: null,
  });
  useEffect(() => {
    setData({
      phone: null,
      name: null,
      message: null,
    });
  }, []);

  useEffect(() => {
    if(!isOpen) {
      setData({
        phone: null,
        name: null,
        message: null,
      });
    }
  }, [isOpen]);

  const onChangeData = (field: "phone" | "name" | "message", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };
  const closeModal = () => {
    setData({
      phone: null,
      name: null,
      message: null,
    });
    onClose()
  }
  /*
  validation
  */
  // const [valid, setValid] = useState<boolean>(false);
  // useEffect(() => {
  //   if (data.email !== null && data.message !== null && data.name !== null) {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //   }
  // }, [data]);
  return (
    <Modal isOpen={isOpen} toggle={closeModal} tabIndex={-1} centered scrollable >
      <ModalHeader  toggle={closeModal} className="bg-primary">
      <div className="modal-title-custom text-white font-size-16 ">
      Create Contact
      </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
              Phone
            </Label>
            <div className="form-control">

              <PhoneInput
                id="AddContactModalemail-input"
                placeholder="Enter Phone"

                rules={{ required: true }}
                value={data["phone"] || ""}
                onChange={(e: any) => {
                  console.log(e)
                  if(e)
                     onChangeData("phone", e);
              }} />
            </div>
            {/*<Input*/}
            {/*  required={true}*/}
            {/*  className="form-control"*/}
            {/*  */}
            {/*  */}
            {/*/>*/}
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Name
            </Label>
            <Input
              type="text"
              required={true}
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Name"
              value={data["name"] || ""}
              onChange={(e: any) => {
                onChangeData("name", e.target.value);
              }}
            />
          </div>
          <div className="">
            <Label
              htmlFor="AddContactModal-invitemessage-input"
              className="form-label"
            >
              Invitation Message
            </Label>
            <textarea
              required={true}
              value={data["message"] || ""}
              onChange={(e: any) => {
                onChangeData("message", e.target.value);
              }}
              maxLength={40}
              className="form-control"
              id="AddContactModal-invitemessage-input"
              rows={3}
              placeholder="Enter Message"
            ></textarea>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={closeModal}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          // disabled={!valid}
          onClick={() => onInvite(data)}
        >
          Invite
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
