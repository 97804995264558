// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Alert, Row, Col, Form, Button, UncontrolledTooltip } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, Navigate, useNavigate } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// hooks
import { useProfile } from "../../hooks";
import { createSelector } from "reselect";
//actions
import { changeTab, loginUser, registerUser, validateCode } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import { TABS } from "../../constants";

interface RegisterProps {}
const Register = (prps: RegisterProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const navigate = useNavigate();
  // const { user, registrationError, regLoading } = useAppSelector(state => ({
  //   user: state.Register.user,
  //   registrationError: state.Register.registrationError,
  //   regLoading: state.Register.loading,
  //   isUserRegistered: state.Register.isUserRegistered,
  // }));
    const [value, setValue] = useState()
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [formValue, setFormValue] = useState();
    const formValueRef = useRef(formValue)
  const errorData = createSelector(
    (state : any) => state.Register,
    (state) => ({
      user: state.user,
      registrationError: state.registrationError,
      regLoading: state.loading,
      isUserRegistered: state.isUserRegistered,
      validate_code: state.validate_code
    })
  );
  // Inside your component
  const { user, registrationError, regLoading, validate_code, isUserRegistered} = useAppSelector(errorData);
  const errorData2 = createSelector(
    (state : any) => state.Login,
    (state) => ({
      isUserLogin: state.isUserLogin,
      error: state.error,
      loginLoading: state.loading,
      isUserLogout: state.isUserLogout,
      isValidate: state.isValidate,
      contacts: state.contacts
    })
  );
  const { isUserLogin, error, loginLoading, isValidate,isUserLogout,contacts} = useAppSelector(errorData2);
  const resolver = yupResolver(
    yup.object().shape({
      // email: yup
      //   .string()
      //   .email("This value should be a valid email.")
      //   .required("Please Enter E-mail."),
      phone: yup.string().required("Please Enter Phone"),
      first_name: yup.string().required("Please Enter First Name."),
      last_name: yup.string().required("Please Enter Last Name."),
      //password: yup.string().required("Please Enter Password."),
    })
  );
  useEffect(() => {
      if (isUserLogin && !loginLoading && !isUserLogout) {
        if(!contacts) dispatch(changeTab(TABS.CONTACTS))
        navigate(redirectUrl);
      }
    }, [isUserLogin, navigate, loginLoading, isUserLogout, redirectUrl]);

  useEffect(() => {
    if(isUserRegistered)
     dispatch(loginUser(formValueRef.current))
  }, [isUserRegistered]);

  const defaultValues: any = {password: "12345678",};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: object) => {
    console.log("Here in submit validate_code",values)

    if(validate_code)
      dispatch(validateCode(values));
    else
    dispatch(registerUser(values));

    setFormValue(values)
    formValueRef.current = values;
  };

  const { userProfile, loading } = useProfile();

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: "/dashboard" }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Register Account"
              subtitle="Get your free Wiconnect account now."
            />

            {user && (user && validate_code) ? (
              <Alert color="success">Code Sent to User</Alert>
            ) : null}

            {user && (user && isUserRegistered) ? (
              <Alert color="success">Register User Successfully</Alert>
            ) : null}

            {registrationError && registrationError ? (
              <Alert color="danger">{registrationError}</Alert>
            ) : null}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {regLoading && <Loader />}
              {/*<div className="mb-3">
                <FormInput
                  label="Username"
                  type="text"
                  name="username"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter username"
                  className="form-control"
                />
              </div>*/}

              <div className="mb-3">
                <FormInput
                  label="First Name"
                  type="text"
                  name="first_name"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter First Name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Last Name"
                  type="text"
                  name="last_name"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Last Name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label form-label">Phone</label>
                 <div className="form-control">

                <PhoneInputWithCountry
                  name="phone"
                  control={control}
                  rules={{ required: true }} />

                 </div>


                {/*<FormInput*/}
                {/*  label="Phone"*/}
                {/*  type="text"*/}
                {/*  name="phone"*/}
                {/*  register={register}*/}
                {/*  errors={errors}*/}
                {/*  control={control}*/}
                {/*  labelClassName="form-label"*/}
                {/*  placeholder="Enter Phone"*/}
                {/*  className="form-control"*/}
                {/*/>*/}
              </div>

              <div className="mb-3">
                <FormInput
                  label="Password"
                  type="hidden"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  className="form-control pe-5"
                  placeholder="Enter Password"

                />
              </div>
              {validate_code &&
                <div className="mb-3">
                  <FormInput
                    label="Code"
                    type="text"
                    name="code"
                    register={register}
                    errors={errors}
                    control={control}
                    labelClassName="form-label"
                    className="form-control pe-5"
                    placeholder="Enter Code"
                  />
                </div>
              }
              <div className="mb-4">
                <p className="mb-0">
                  You agree to the{" "}
                  <Link to="#" className="text-primary">
                    Terms of Use
                  </Link>
                </p>
              </div>

              {!validate_code &&
              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  Register
                </Button>
              </div>
              }
              {validate_code &&
              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  Validate Code
                </Button>
              </div>
              }
              {/*<div className="mt-4 text-center">*/}
              {/*  <div className="signin-other-title">*/}
              {/*    <h5 className="font-size-14 mb-4 title">Sign up using</h5>*/}
              {/*  </div>*/}
              {/*  <Row className="">*/}
              {/*    <div className="col-4">*/}
              {/*      <div>*/}
              {/*        <button*/}
              {/*          type="button"*/}
              {/*          className="btn btn-light w-100"*/}
              {/*          id="facebook"*/}
              {/*        >*/}
              {/*          <i className="mdi mdi-facebook text-indigo"></i>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <UncontrolledTooltip placement="top" target="facebook">*/}
              {/*        Facebook*/}
              {/*      </UncontrolledTooltip>*/}
              {/*    </div>*/}
              {/*    <div className="col-4">*/}
              {/*      <div>*/}
              {/*        <button*/}
              {/*          type="button"*/}
              {/*          className="btn btn-light w-100"*/}
              {/*          id="twitter"*/}
              {/*        >*/}
              {/*          <i className="mdi mdi-twitter text-info"></i>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <UncontrolledTooltip placement="top" target="twitter">*/}
              {/*        Twitter*/}
              {/*      </UncontrolledTooltip>*/}
              {/*    </div>*/}
              {/*    <div className="col-4">*/}
              {/*      <div>*/}
              {/*        <button*/}
              {/*          type="button"*/}
              {/*          className="btn btn-light w-100"*/}
              {/*          id="google"*/}
              {/*        >*/}
              {/*          <i className="mdi mdi-google text-danger"></i>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*      <UncontrolledTooltip placement="top" target="google">*/}
              {/*        Google*/}
              {/*      </UncontrolledTooltip>*/}
              {/*    </div>*/}
              {/*  </Row>*/}
              {/*</div>*/}
            </Form>

            <div className="mt-5 text-center text-muted">
              <p>
                Already have an account ?{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
