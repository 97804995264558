import axios from "axios";
import config from "../config";
import { insertJWTParams } from "../helpers/jwt_helpers";
import { jwtDecode } from "jwt-decode";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: any) => {
    if (params && params.auth) {
      delete params.auth
      return axios.get(url, insertJWTParams(params))
    }
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: {}, params?: any) => {
    if (params && params.auth) {
      delete params.auth;
      return axios.post(url, data, insertJWTParams(params));

    }
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
  */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    /*const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);*/
  };

  /*
   file upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    /*const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };*/
    return axios.post(url, formData);
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");


  if (!user) {
    return null;
  } else {
    const js_string = JSON.stringify(user)
    let decode = jwtDecode(JSON.stringify(js_string))
    return {...decode,...JSON.parse(user)};
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
