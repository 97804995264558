import {
  call,
  put,
  takeEvery,
  // takeLatest
} from "redux-saga/effects";

// Login Redux States
import { AuthLoginActionTypes } from "./types";
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getFirebaseBackend,
  setLoggeedInUser,
} from "../../../helpers/firebase_helper";
import {
  // postFakeLogin,
  postJwtLogin, postJwtValidateLogin
  // postSocialLogin,
} from "../../../api/index";
import {
  parseJwt,
} from "../../../helpers/jwt_helpers"
import { jwtDecode } from "jwt-decode";
import axios, { AxiosError } from "axios";
import * as url from "../../../api/urls";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(
        fireBaseBackend.loginUser,
        user.phone,
        user.password,

      );
      // myData
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

      // axios.post(url.POST_JWT_LOGIN, {
      //   phone: user.phone,
      //   password: user.password,
      // }, {
      //   headers: {
      //     'Content-Type': 'application/json', // Adjust this based on your server's expected Content-Type
      //   },
      //   responseType: 'json', // Set the expected response type
      // }).then(response =>{
      //   console.log(response.data);
      // }).catch(error =>{
      //
      //   console.log("here",error.response)
      //
      // });
      const response: Promise<any> = yield call(postJwtLogin, {
        phone: user.phone,
        password: user.password,
        code: user.code
      });


      const js_string = JSON.stringify(response)
      let decode = jwtDecode(JSON.stringify(js_string))
      console.log(decode)
      const js_tokens = JSON.parse(js_string)
      const auth_user = { ...js_tokens, ...decode}

      setLoggeedInUser(auth_user);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      /*const response: Promise<any> = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      setLoggeedInUser(response);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );*/
    }
  } catch (error: any) {
        console.log(error.request)
        console.log(error.response)


    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}

/*function* socialLogin({ payload: { data, type } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend = getFirebaseBackend();
      const response: Promise<any> = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      );
      setLoggeedInUser(response);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    } else {
      const response: Promise<any> = yield call(postSocialLogin, data);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    }
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}*/

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(fireBaseBackend.logout);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, response)
      );
    } else {
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );
    }
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

function* loginValidateUser({ payload: { user } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(
        fireBaseBackend.loginUser,
        user.phone,
        user.password
      );
      // myData
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

      // axios.post(url.POST_JWT_LOGIN, {
      //   phone: user.phone,
      //   password: user.password,
      // }, {
      //   headers: {
      //     'Content-Type': 'application/json', // Adjust this based on your server's expected Content-Type
      //   },
      //   responseType: 'json', // Set the expected response type
      // }).then(response =>{
      //   console.log(response.data);
      // }).catch(error =>{
      //
      //   console.log("here",error.response)
      //
      // });
      console.log("Calling an API")
      const response: Promise<any> = yield call(postJwtValidateLogin, {
        phone: user.phone,
        password: user.password,
      });

      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_VALIDATE_USER, response)
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      /*const response: Promise<any> = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      setLoggeedInUser(response);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );*/
    }
  } catch (error: any) {
        console.log(error.request)
        console.log(error.response)


    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_VALIDATE_USER, error)
    );
  }
}


function* loginSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGIN_VALIDATE_USER, loginValidateUser);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
  // yield takeLatest(AuthLoginActionTypes.SOCIAL_LOGIN, socialLogin);
}

export default loginSaga;
