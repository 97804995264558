const authAccessHeader = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));

  if (obj && obj.access) {
    return obj.access;
  } else {
    return "";
  }
}

const insertJWTParams = (obj) => {
  obj.headers = {"Authorization": `Bearer ${authAccessHeader()}`}
  return obj
}

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64)
    .split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

  return JSON.parse(jsonPayload);
}

export {
  insertJWTParams, parseJwt
}
