//@ts-nocheck
import { APIClient } from "./apiCore";
import { APIWSClient } from "./apiWSCore"

import * as url from "./urls";

const api = new APIClient();

// Contact
const addContacts = (contacts: Array<string | number>) => {
  return api.create(url.ADD_CONTACTS, contacts);
};

const getChatUserDetails = (id: string | number) => {
  const params = {auth: true, id}
  return api.get(url.GET_CHAT_USER_DETAILS + "/" + id, params);
};

// Conversations
const getDirectMessages = () => {
  const params = {auth: true}
  return api.get(url.GET_DIRECT_MESSAGES, params);
};

const getChatUserConversations = (id: string | number) => {
  const params = {auth: true}
  return api.get(url.GET_CHAT_USER_CONVERSATIONS + "/" + id,
    params);
};

const readConversation = (id: string | number) => {
  console.log(">>> read conversation id", id)
  return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
};

// WebSocket Communication
const sendMessage = (data: object) => {
  // return api.create(url.SEND_MESSAGE, data);
  let newData = { ...data, action: 'message' };
  if(data.action && data.action === 'reset') {
    console.log("sending reset", data)
    newData = { ...data };
  }
  console.log("   >>> sending data ", newData)
  const wsapi = APIWSClient.getInstance();
  wsapi.emit('message', newData);
};

const getMessage = (callback : any) => {
  console.log("  API COnnect 2 ")

  const wsapi = APIWSClient.getInstance();
  wsapi.onDisconnect();
  wsapi.on('message',(data) => {
      console.log("raheel with data ",data)
     // if(data.action !== 'onlineUser')
        callback(data)
  })
}

const disconnect = () => {
  console.log("API connect3")
  const wsapi = APIWSClient.getInstanceObject();
  if(wsapi)
    wsapi.disconnect()
}

const receiveMessage = (data: any) => {
  console.log(">>> receiving message ", data)

  return new Promise((resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        setTimeout(() => {
          reject(["Your id is not found"]);
        }, 500);
      }
    });
 // return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  console.log(">>> reading message ", id)
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const receiveMessageFromUser = (id: string | number) => {
  console.log(">>> receiving message from user", id)
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
    params: { id },
  });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  console.log(">>> delete message from userId and messageId ", userId, " ", messageId)
  return api.delete(url.DELETE_MESSAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId },
  });
};

const forwardMessage = (data: object) => {
  console.log(">>> forwarding message data", data)
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  console.log(">>> delete user message from userId", userId)
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

// Channels and Favourites
/*const getChannels = () => {
  return api.get(url.GET_CHANNELS);
};*/

const createChannel = (data: object) => {
  return api.create(url.CREATE_CHANNEL, data);
};

const getChannelDetails = (id: string | number) => {
  return api.get(url.GET_CHANNEL_DETAILS + "/" + id, { params: { id } });
};

const getFavourites = () => {
  return api.get(url.GET_FAVOURITES);
};

const toggleFavouriteContact = (id: string | number) => {
  return api.update(url.TOGGLE_FAVOURITE_CONTACT + "/" + id, {
    params: { id },
  });
};

// Archive Contacts
/*const getArchiveContact = () => {
  return api.get(url.GET_ARCHIVE_CONTACT);
};*/

const toggleArchiveContact = (id: string | number) => {
  return api.update(url.TOGGLE_ARCHIVE_CONTACT + "/" + id, { params: { id } });
};

// Image
const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

export {
  getFavourites,
  getDirectMessages,
  // getChannels,
  addContacts,
  createChannel,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  getChannelDetails,
  toggleFavouriteContact,
  // getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
  getMessage,
  disconnect
};
