// auth
export const POST_JWT_LOGIN = "/login";
export const POST_JWT_VALIDATE_LOGIN = "/login-validation";

export const JWT_REGISTER = "/signup";
export const JWT_CODE = "/validate-code";
export const POST_JWT_PASSWORD_FORGET = "/password-reset";
// profile & settings
export const GET_PROFILE_DETAILS = "/users/profile";
// contacts
export const GET_CONTACTS = "/users/contacts";
export const INVITE_CONTACT = "/invites/";
// calls
// bookmarks
// chats
export const GET_DIRECT_MESSAGES = "/chats2/contactsdm";
export const GET_CHAT_USER_CONVERSATIONS = "/chats2/conversation";
export const GET_CHAT_USER_DETAILS = "/users/contact";
// groups


// Remaining ENDPOINTS
// auth
/*export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const JWT_REGISTER = "/post-jwt-register";
export const POST_FAKE_REGISTER = "/post-fake-register";*/

export const USER_CHANGE_PASSWORD = "/user-change-password";

// profile & settings
// export const GET_PROFILE_DETAILS = "/profile-details";
 export const GET_USER_SETTINGS = "/user-settings";
export const UPDATE_ETTINGS = "/update-user-settings";

// contacts
/*export const GET_CONTACTS = "/user-contacts";
export const INVITE_CONTACT = "/invite-contact";*/

// calls
// export const GET_CALLS_LIST = "/calls-list";

// bookmarks
// export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_FAVOURITES = "/get-favourites";
/*export const GET_DIRECT_MESSAGES = "/get-direct-messages";
export const GET_CHANNELS = "/get-channles";*/
export const ADD_CONTACTS = "/add-contact";
export const CREATE_CHANNEL = "/create-channel";
/*export const GET_CHAT_USER_DETAILS = "/get-user-details";
export const GET_CHAT_USER_CONVERSATIONS = "/get-user-conversations";*/
export const SEND_MESSAGE = "/send-message";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
// export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";

// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";
