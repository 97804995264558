import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getProfileDetails = () => {
  return api.get(url.GET_PROFILE_DETAILS, {auth: true});
};

const getSettings = () => {
  console.log("Get settings")
  return api.get(url.GET_USER_SETTINGS, {auth: true});
};
const updateSettings = (field: string, value: any) => {
  return api.update(url.UPDATE_ETTINGS, {
    field: field,
    value: value,
    auth:true
  });
};

export {
  getProfileDetails,
  getSettings,
  updateSettings
};
